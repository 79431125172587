import { brand, ui } from '@/assets';
import { PAGE_TITLE, project } from '@/constants';
import { Context } from '@/context';
import useDocumentScrollThrottled from '@/hooks/useDocumentScrollThrottled';
import scssStyles from '@/utils/scssStyles';
import { HamburguerButton } from '@components/index';
import { observer } from 'mobx-react-lite';
import Link from 'next/link';
import { FC, useContext, useState } from 'react';
import styles from './Header.module.scss';
import Image from 'next/image';

const Header: FC = observer(() => {
  const [shouldHideHeader, setShouldHideHeader] = useState(false);
  const { dispatch, state } = useContext(Context);

  const MINIMUM_SCROLL = 80;
  const TIMEOUT_DELAY = 400;

  let timeout: NodeJS.Timeout;

  useDocumentScrollThrottled((callbackData) => {
    const { previousScrollTop, currentScrollTop } = callbackData;
    const isScrolledDown = previousScrollTop < currentScrollTop;
    const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL;
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      setShouldHideHeader(isScrolledDown && isMinimumScrolled);
    }, TIMEOUT_DELAY);
  });

  return (
    <div
      className={scssStyles([
        styles.container,
        shouldHideHeader ? styles.hide : '',
      ])}
      style={{ top: state.layout.bannerActive ? '80px' : 0 }}
    >
      <div className={scssStyles([styles.mobile])}>
        <HamburguerButton
          title="Abrir menu"
          handleClick={() => {
            const open = !state.layout.menuOpen;
            dispatch({
              type: 'SET_MENU_OPEN',
              payload: { menuOpen: open },
            });
          }}
        />
        <div>
          <Link href="/">
            <a title={PAGE_TITLE} className={styles.title}>
              <Image
                src={brand.logoHeaderMobile}
                alt={project.brand}
                width={115}
                height={19}
              />
            </a>
          </Link>
        </div>
        <div />
      </div>
    </div>
  );
});

export default Header;
