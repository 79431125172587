import { productDetails as productDetailsAssets } from "@/assets";
import { PRODUCT_DETAILS_REF } from "@/data/menu.data";
import { MenuLabels } from "@/models";
import { IncludePrefixResponsiveSizes } from "@/utils/imagePrefixes";

export interface ProductTabSlide {
  id: string;
  index?: number;
  title: string;
  slug: string;
  linkBtn?: string;
  labelBtn?: string;
  titleBtn?: string;
  altBtn?: string;
  titleFirst?: string;
  titleSecond?: string;
  highLightedFirst: boolean;
  breakTitle?: boolean;
  descriptionDesktop: JSX.Element;
  descriptionMobile: JSX.Element;
  parentSlug?: string;
  parentTitle?: string;
  image: IncludePrefixResponsiveSizes;
  vimeoId?: {
    mobile: string;
    desktop: string;
  };
  tabId: string;
  fileUrl?: string;
}

export interface ProductTab {
  id: string;
  title: string;
  slug: string;
  parentSlug?: string;
  children: Omit<ProductTabSlide, "tabId">[];
}

export const productDetails: ProductTab[] = [
  {
    id: "bello-design",
    slug: "bello-design",
    title: "Bello Design",
    children: [
      {
        id: "farois-de-neblina-em-led",
        slug: "farois-de-neblina-em-led",
        title: "Faróis de neblina em led",
        highLightedFirst: true,
        parentSlug: "bello-design",
        parentTitle: "Bello Design",
        descriptionDesktop: (
          <p>
            Redesenhado com o melhor estilo italiano, <br />
            os faróis em LED* têm tudo o que você precisa <br />
            para dirigir a noite.
          </p>
        ),
        descriptionMobile: (
          <p>
            Redesenhado com o melhor estilo italiano, os faróis em LED* têm tudo
            o que você precisa para dirigir a noite.
          </p>
        ),
        image: productDetailsAssets.belloDesign.designItaliano,
      },
      {
        id: "o-verdadeiro-5-lugares-e-4-portas",
        slug: "o-verdadeiro-5-lugares-e-4-portas",
        title: "O verdadeiro 5 lugares e 4 portas",
        highLightedFirst: true,
        parentSlug: "bello-design",
        parentTitle: "Bello Design",
        descriptionDesktop: (
          <p>
            Todo mundo pode viajar confortável na Fiat Strada, <br />
            que conta com cabine dupla de 5 lugares e 4 portas.
          </p>
        ),
        descriptionMobile: (
          <p>
            Todo mundo pode viajar confortável na Fiat Strada, que conta com
            cabine dupla de 5 lugares e 4 portas.
          </p>
        ),
        image: productDetailsAssets.belloDesign.cabineCom5LugaresE4Portas,
      },
      {
        id: "espaco-e-conforto",
        slug: "espaco-e-conforto",
        title: "Espaço e conforto",
        highLightedFirst: true,
        parentSlug: "bello-design",
        parentTitle: "Bello Design",
        descriptionDesktop: (
          <p>
            Espaço interno para toda a família com bancos modernos e revestidos
            em couro.
          </p>
        ),
        descriptionMobile: (
          <p>
            Aqui, tudo é um pouco italiano: além de cabine com espaço para toda
            famiglia, o interior do carro tem um toque de elegância nos bancos
            de couro/tecido* e o toque macio do volante em couro.
          </p>
        ),
        image: productDetailsAssets.belloDesign.espacoEConforto,
      },
      {
        id: "rodas-em-liga-leve",
        slug: "rodas-em-liga-leve",
        title: "Rodas em liga leve",
        highLightedFirst: true,
        parentSlug: "bello-design",
        parentTitle: "Bello Design",
        descriptionDesktop: (
          <p>
            Robustez é a palavra-chave da Fiat Strada, que <br />
            possui rodas de liga leve 16’’ + ATR (pneus de uso misto), <br />
            além de design exclusivo para cada personalidade, seja <br />
            mais urbana ou livre para qualquer caminho.
          </p>
        ),
        descriptionMobile: (
          <p>
            Robustez é a palavra-chave da Fiat Strada, que possui rodas de liga
            leve 16’’ + ATR (pneus de uso misto), além de design exclusivo para
            cada personalidade, seja mais urbana ou livre para qualquer caminho
          </p>
        ),
        image: productDetailsAssets.belloDesign.rodasEmLigaLeve,
      },
      {
        id: "design-externo",
        slug: "design-externo",
        title: "Design externo",
        highLightedFirst: true,
        parentSlug: "bello-design",
        parentTitle: "Bello Design",
        descriptionDesktop: (
          <p>
            Robustez, dinamismo e versatilidade é tudo o que o visual da Strada
            define.
          </p>
        ),
        descriptionMobile: (
          <p>
            Com barras de teto na cor cinza, retrovisor preto brilhante, estribo
            lateral e badges externos, o modelo Ranch da Fiat Strada está
            belíssimo também por fora.
          </p>
        ),
        image: productDetailsAssets.belloDesign.designExterno,
      },
    ],
  },
  {
    id: "forca-e-potencia",
    slug: "forca-e-potencia",
    title: "Força e potencia",
    children: [
      {
        id: "cacamba",
        slug: "cacamba",
        highLightedFirst: true,
        parentSlug: "forca-e-potencia",
        parentTitle: "Força e potencia",
        title: "Caçamba",
        descriptionDesktop: (
          <p>
            O maior volume de carga da categoria, com 1.354L <br />e capacidade
            de carga de até 720kg na versão cabine plus, <br />
            além de volume de 844L e capacidade de até 650kg <br />
            nas versões cabine dupla turbo automática.
          </p>
        ),
        descriptionMobile: (
          <p>
            O maior volume de carga da categoria, com 1.354L e capacidade de
            carga de até 720kg na versão cabine plus, além de volume de 844L e
            capacidade de até 650kg nas versões cabine dupla turbo automática.
          </p>
        ),
        image: productDetailsAssets.forcaEPotencia.cacamba,
      },
      {
        id: "maior-altura-da-categoria",
        slug: "maior-altura-da-categoria",
        highLightedFirst: true,
        parentSlug: "forca-e-potencia",
        parentTitle: "Força e potencia",
        title: "Maior altura da categoria",
        descriptionDesktop: (
          <p>
            A nova Strada é a melhor do segmento em altura <br />
            do solo (214 mm) e ângulos de entrada (24°) <br />e saída (28°).
          </p>
        ),
        descriptionMobile: (
          <p>
            A nova Strada é a melhor do segmento em altura do solo (214 mm) e
            ângulos de entrada (24°) e saída (28°).
          </p>
        ),
        image: productDetailsAssets.forcaEPotencia.maiorAlturaDaCategoria,
      },
      {
        id: "vao-modular",
        slug: "vao-modular",
        highLightedFirst: true,
        parentSlug: "forca-e-potencia",
        parentTitle: "Força e potencia",
        title: "Vão modular",
        descriptionDesktop: (
          <p>
            Com 4 ganchos inferiores e até 6 ganchos superiores, <br />
            além de um sistema exclusivo de iluminação, a Fiat <br />
            Strada tem um vão personalizável e otimizado para <br />
            diferentes funções, cargas e desafios.
          </p>
        ),
        descriptionMobile: (
          <p>
            Com 4 ganchos inferiores e até 6 ganchos superiores, além de um
            sistema exclusivo de iluminação, a Fiat Strada tem um vão
            personalizável e otimizado para diferentes funções, cargas e
            desafios.
          </p>
        ),
        image: productDetailsAssets.forcaEPotencia.vaoModular,
      },
      {
        id: "suspensao-do-feixe-de-molas",
        slug: "suspensao-do-feixe-de-molas",
        highLightedFirst: true,
        parentSlug: "forca-e-potencia",
        parentTitle: "Força e potencia",
        title: "Suspensão do feixe de molas",
        descriptionDesktop: (
          <p>
            Mantivemos o sistema de suspensão, mas ele está <br />
            ainda melhor e mais resistente. A suspensão absorve <br />
            os impactos e suporta o peso do carro e de cargas <br />
            mais pesadas.
          </p>
        ),
        descriptionMobile: (
          <p>
            Mantivemos o sistema de suspensão, mas eles está ainda melhor e mais
            resistente. A suspensão absorve os impactos e suporta o peso do
            carro e de cargas mais pesadas.
          </p>
        ),
        image: productDetailsAssets.forcaEPotencia.suspensaoDoFeixeDeMolas,
      },
      {
        id: "melhor-consumo-de-combustivel",
        slug: "melhor-consumo-de-combustivel",
        highLightedFirst: true,
        parentSlug: "forca-e-potencia",
        parentTitle: "Força e potencia",
        title: "Melhor consumo de combustível",
        descriptionDesktop: (
          <p>
            Aventure-se com a face esportiva da Nova Fiat Strada através do
            botão Sport. Ao acioná-lo, o carro fica mais sensível, com respostas
            mais ágeis, e você acelera menos para receber mais potência.
            Incrível, não é mesmo?
          </p>
        ),
        descriptionMobile: (
          <p>
            Aventure-se com a face esportiva da Nova Fiat Strada através do
            botão Sport. Ao acioná-lo, o carro fica mais sensível, com respostas
            mais ágeis, e você acelera menos para receber mais potência.
            Incrível, não é mesmo?
          </p>
        ),
        image: productDetailsAssets.forcaEPotencia.melhorConsumoDeCombustivel,
      },
      {
        id: "motor-turbo-130-cv",
        slug: "motor-turbo-130-cv",
        highLightedFirst: true,
        parentSlug: "forca-e-potencia",
        parentTitle: "Força e potencia",
        title: "Motor turbo 130 cv",
        descriptionDesktop: (
          <p>
            A primeira picape compacta com motor turbo do <br />
            mundo. A inovação está no DNA da Strada e isso <br />
            se comprova com a chegada da motorização 1.0 <br />
            Turbo T200 de 130cv e 200Nm.
          </p>
        ),
        descriptionMobile: (
          <p>
            A primeira picape compacta com motor turbo do mundo. A inovação está
            no DNA da Strada e isso se comprova com a chegada da motorização 1.0
            Turbo T200 de 130cv e 200Nm.
          </p>
        ),
        image: productDetailsAssets.forcaEPotencia.motorTurbo130Cv,
      },
      {
        id: "tc-controle-de-tracao",
        slug: "tc-controle-de-tracao",
        highLightedFirst: true,
        parentSlug: "forca-e-potencia",
        parentTitle: "Força e potencia",
        title: "TC + Controle de tração",
        descriptionDesktop: (
          <p>
            O TC+ ou controle de tração avançado é o que vai te <br />
            ajudar a sair dos obstáculos. Ele identifica a roda que <br />
            gira em falso e distribui a força do motor para outra <br />
            roda.
          </p>
        ),
        descriptionMobile: (
          <p>
            O TC+ ou controle de tração avançado é o que vai te ajudar a sair
            dos obstáculos. Ele identifica a roda que gira em falso e distribui
            a força do motor para outra roda.
          </p>
        ),
        image: productDetailsAssets.forcaEPotencia.tcControleDeTracao,
      },
    ],
  },
  {
    id: "tecnologia",
    slug: "tecnologia",
    title: "Tecnologia",
    children: [
      {
        id: "central-multimidia",
        slug: "central-multimidia",
        title: "CENTRAL MULTIMÍDIA",
        highLightedFirst: true,
        parentSlug: "tecnologia",
        parentTitle: "Tecnologia",
        descriptionDesktop: (
          <p>
            Mais tecnológica, a nova Central Multimídia* de 7’’ <br />
            conecta ao seu smarthphone sem usar cabos, via <br />
            Andoid Auto e Apple Car Play para você usar apps <br />
            como Spotify e Waze.
          </p>
        ),
        descriptionMobile: (
          <p>
            Mais tecnológica, a nova Central Multimídia* de 7’’ conecta ao seu
            smarthphone sem usar cabos, via Andoid Auto e Apple Car Play para
            você usar apps como Spotify e Waze.
          </p>
        ),
        image: productDetailsAssets.tecnologia.centralMultimidia,
      },
      {
        id: "wireless-charger",
        slug: "wireless-charger",
        highLightedFirst: true,
        parentSlug: "tecnologia",
        parentTitle: "Tecnologia",
        title: "Wireless Charger",
        descriptionDesktop: (
          <p>
            Fique sempre conectado. A sua Fiat Strada é equipada <br />
            com a tecnologia Wireless Charger*, que permite <br />
            carregar seu celular por indução, sem o uso de fios.
          </p>
        ),
        descriptionMobile: (
          <p>
            Fique sempre conectado. A sua Fiat Strada é equipada com a
            tecnologia Wireless Charger*, que permite carregar seu celular por
            indução, sem o uso de fios.
          </p>
        ),
        image: productDetailsAssets.tecnologia.wirelessCharger,
      },
      {
        id: "ar-condicionado-de-serie",
        slug: "ar-condicionado-de-serie",
        highLightedFirst: true,
        parentSlug: "tecnologia",
        parentTitle: "Tecnologia",
        title: "Ar-condicionado de série",
        descriptionDesktop: (
          <p>
            Controle o clima com botões de fácil e rápido acesso <br />
            de acordo com sua preferência: nas versões Ranch e Volcano, o
            <br />
            ar-condicionado automático digital é de série mais conforto <br />e
            tecnologia.
          </p>
        ),
        descriptionMobile: (
          <p>
            Além de espaçosa, a cabine da nova Fiat Strada também é ambientada
            por um potente ar-condicionado, que vem de série em todas as
            versões.
          </p>
        ),
        image: productDetailsAssets.tecnologia.arCondicionadoDeSerie,
      },
      {
        id: "painel-35-tft",
        slug: "painel-35-tft",
        highLightedFirst: true,
        parentSlug: "tecnologia",
        parentTitle: "Tecnologia",
        title: "Painel 3.5'' TFT",
        descriptionDesktop: (
          <p>
            Além de personalizar as principais informações, com o <br />
            painel de instrumentos de 3,5” em TFT você também <br />
            pode conferir o desempenho do seu Fiat Strada nas <br />
            telas de performance.
          </p>
        ),
        descriptionMobile: (
          <p>
            A nova Fiat Strada tem a tampa traseira tão leve que você pode abrir
            e fechar com apenas uma mão. Além de leve, ela é super resistente –
            aguentando até 400 kg em cima da tampa aberta.
          </p>
        ),
        image: productDetailsAssets.tecnologia.painel35Tft,
      },
      {
        id: "nova-tecnologia-na-tampa-traseira",
        slug: "nova-tecnologia-na-tampa-traseira",
        highLightedFirst: true,
        parentSlug: "tecnologia",
        parentTitle: "Tecnologia",
        title: "Nova tecnologia na tampa traseira",
        descriptionDesktop: (
          <p>
            A nova Fiat Strada tem a tampa traseira tão leve que <br />
            você pode abrir e fechar com apenas uma mão. Além <br />
            de leve, ela é super resistente – aguentando até 400 kg <br />
            em cima da tampa aberta.
          </p>
        ),
        descriptionMobile: (
          <p>
            A nova Fiat Strada tem a tampa traseira tão leve que você pode abrir
            e fechar com apenas uma mão. Além de leve, ela é super resistente –
            aguentando até 400 kg em cima da tampa aberta.
          </p>
        ),
        image: productDetailsAssets.tecnologia.novaTecnologiaNaTampaTraseira,
      },
    ],
  },
  {
    id: "seguranca",
    slug: "seguranca",
    title: "Segurança",
    children: [
      {
        id: "airbags-laterais",
        slug: "airbags-laterais",
        title: "Airbags laterais",
        parentSlug: "seguranca",
        parentTitle: "Segurança",
        highLightedFirst: true,
        descriptionDesktop: (
          <p>
            Agora além dos airbags frontais, suas viagens estão <br />
            protegidas por todos os lados, graças aos novos <br />
            airbags laterais.
          </p>
        ),
        descriptionMobile: (
          <p>
            Agora além dos airbags frontais, suas viagens estão protegidas por
            todos os lados, graças aos novos airbags laterais.
          </p>
        ),
        image: productDetailsAssets.seguranca.airbagsLaterais,
      },
      {
        id: "camera-de-re-e-sensor-de-estacionamento",
        slug: "camera-de-re-e-sensor-de-estacionamento",
        title: "Câmera de ré e sensor de estacionamento",
        highLightedFirst: true,
        parentSlug: "seguranca",
        parentTitle: "Segurança",
        descriptionDesktop: (
          <p>
            Com o sensor de estacionamento sonoro e câmera de <br />
            ré com linhas dinâmicas, estacionar e desviar de <br />
            obstáculos nunca foi tão fácil.
          </p>
        ),
        descriptionMobile: (
          <p>
            Com o sensor de estacionamento sonoro e câmera de ré com linhas
            dinâmicas, estacionar e desviar de obstáculos nunca foi tão fácil.
          </p>
        ),
        image: productDetailsAssets.seguranca.cameraDeReESensorDeEstacionamento,
      },
      {
        id: "controle-de-estabilidade",
        slug: "controle-de-estabilidade",
        title: "Controle de estabilidade",
        highLightedFirst: true,
        parentSlug: "seguranca",
        parentTitle: "Segurança",
        descriptionDesktop: (
          <p>
            O controle de estabilidade te ajuda a manter a direção em <br />
            curvas mais acentuadas ou em derrapagem.
          </p>
        ),
        descriptionMobile: (
          <p>
            O controle de estabilidade ajuda você a manter a direção em curvas
            mais acentuadas ou em derrapagens
          </p>
        ),
        image: productDetailsAssets.seguranca.controleDeEstabilidade,
      },
      {
        id: "isofix",
        slug: "isofix",
        title: "Isofix",
        highLightedFirst: true,
        parentSlug: "seguranca",
        parentTitle: "Segurança",
        descriptionDesktop: (
          <p>
            Uma trava de uso prática para fixar a cadeirinha ou o <br />
            bebê conforto ao banco traseiro da sua Fiat Strada.
          </p>
        ),
        descriptionMobile: (
          <p>
            Uma trava de uso prática para fixar a cadeirinha ou o bebê conforto
            ao banco traseiro da sua Fiat Strada
          </p>
        ),
        image: productDetailsAssets.seguranca.isofix,
      },
    ],
  },
  {
    id: "acessorios",
    slug: "acessorios",
    title: "Acessórios",
    children: [
      {
        id: "mais-de-50-acessorios-originais-para-sua-picape",
        slug: "mais-de-50-acessorios-originais-para-sua-picape",
        title: "Mais de 50 acessórios originais para sua picape",
        highLightedFirst: true,
        parentSlug: "acessorios",
        parentTitle: "Acessórios",
        descriptionDesktop: (
          <p>
            Da diversão ao trabalho duro, conheça o portfólio dos <br />
            acessórios originais Fiat Mopar e deixe sua Fiat Strada <br />
            ainda mais versátil e do seu jeito.
          </p>
        ),
        descriptionMobile: (
          <p>
            Da diversão ao trabalho duro, conheça o portfólio dos acessórios
            originais Fiat Mopar e deixe sua Fiat Strada ainda mais versátil e
            do seu jeito.
          </p>
        ),
        image:
          productDetailsAssets.acessorios
            .maisDe50AcessoriosOriginaisParaSuaPicape,
      },
      {
        id: "extensor-de-cacamba",
        slug: "extensor-de-cacamba",
        title: "Extensor de caçamba",
        highLightedFirst: true,
        parentSlug: "acessorios",
        parentTitle: "Acessórios",
        descriptionDesktop: (
          <p>
            A maior caçamba da categoria pode ficar ainda maior <br />
            com o Extensor de Caçamba de engate rápido.
          </p>
        ),
        descriptionMobile: (
          <p>
            A maior caçamba da categoria pode ficar ainda maior com o Extensor
            de Caçamba de engate rápido.
          </p>
        ),
        image: productDetailsAssets.acessorios.extensorDeCacamba,
      },
      {
        id: "engate-reboque",
        slug: "engate-reboque",
        title: "Engate reboque",
        highLightedFirst: true,
        parentSlug: "acessorios",
        parentTitle: "Acessórios",
        descriptionDesktop: (
          <p>
            O engate reboque Mopar possibilita a remoção da <br />
            lança quando não está em uso e não requer nenhuma <br />
            função adicional, mantendo a garantia do veículo.
          </p>
        ),
        descriptionMobile: (
          <p>
            O engate reboque Mopar possibilita a remoção da lança quando não
            está em uso e não requer nenhuma função adicional, mantendo a
            garantia do veículo.
          </p>
        ),
        image: productDetailsAssets.acessorios.engateReboque,
      },
      {
        id: "estribo-lateral",
        slug: "estribo-lateral",
        title: "Estribo lateral",
        highLightedFirst: true,
        parentSlug: "acessorios",
        parentTitle: "Acessórios",
        descriptionDesktop: (
          <p>
            A união da funcionalidade com a proteção na sua Fiat <br />
            Strada. O estribo lateral dá ao seu carro mais estilo <br />
            ainda.
          </p>
        ),
        descriptionMobile: (
          <p>
            A união da funcionalidade com a proteção na sua Fiat Strada. O
            estribo lateral dá ao seu carro mais estilo ainda.
          </p>
        ),
        image: productDetailsAssets.acessorios.estriboLateral,
      },
      {
        id: "santo-antonio",
        slug: "santo-antonio",
        title: "Santo Antônio",
        highLightedFirst: true,
        parentSlug: "acessorios",
        parentTitle: "Acessórios",
        descriptionDesktop: (
          <p>
            Mais que um acessório, um item indispensável na hora <br />
            de amarrar cargas e proteger a carroceria da sua <br />
            picape
          </p>
        ),
        descriptionMobile: (
          <p>
            Mais que um acessório, um item indispensável na hora de amarrar
            cargas e proteger a carroceria da sua picape.
          </p>
        ),
        image: productDetailsAssets.acessorios.santoAntonio,
      },
      {
        id: "capota-maritima",
        slug: "capota-maritima",
        title: "Capota Marítima",
        highLightedFirst: true,
        parentSlug: "acessorios",
        parentTitle: "Acessórios",
        descriptionDesktop: (
          <p>
            Proteção para a caçamba e suas cargas, a capota <br />
            marítima é um item indispensável quando se pensa em <br />
            transporte com segurança.
          </p>
        ),
        descriptionMobile: (
          <p>
            Proteção para a caçamba e suas cargas, a capota marítima é um item
            indispensável quando se pensa em transporte com segurança.
          </p>
        ),
        image: productDetailsAssets.acessorios.capotaMaritima,
      },
    ],
  },
  {
    id: "servicos",
    slug: "servicos",
    title: "Serviços",
    children: [
      {
        id: "plano-de-revisoes-mais-completo-da-categoria",
        slug: "plano-de-revisoes-mais-completo-da-categoria",
        title: "Plano de revisões mais completo da categoria",
        highLightedFirst: true,
        parentSlug: "servicos",
        parentTitle: "Serviços",
        descriptionDesktop: (
          <p>
            Sua nova Fiat Strada bem cuidada e sempre em dia. <br />
            Agende regularmente as revisões programadas em <br />
            nossas concessionárias e mantenha a garantia de 3 <br />
            anos de fábrica.
          </p>
        ),
        descriptionMobile: (
          <p>
            Sua nova Fiat Strada bem cuidada e sempre em dia. Agende
            regularmente as revisões programadas em nossas concessionárias e
            mantenha a garantia de 3 anos de fábrica.
          </p>
        ),
        image:
          productDetailsAssets.servicos.planoDeRevisoesMaisCompletoDaCategoria,
      },
      {
        id: "o-cuidado-que-sua-nova-fiat-strada-merece",
        slug: "o-cuidado-que-sua-nova-fiat-strada-merece",
        title: "O cuidado que sua nova fiat strada merece",
        highLightedFirst: true,
        parentSlug: "servicos",
        parentTitle: "Serviços",
        descriptionDesktop: (
          <p>
            Conheça os pacotes de serviços Fiat Mopar para a sua <br />
            picape. A sua Fiat Strada pode ter até 5 anos de <br />
            garantia, além das revisões sob medida com descontos <br />e preços
            fixados.
          </p>
        ),
        descriptionMobile: (
          <p>
            Conheça os pacotes de serviços Fiat Mopar para a sua picape. A sua
            Fiat Strada pode ter até 5 anos de garantia, além das revisões sob
            medida com descontos e preços fixados.
          </p>
        ),
        image: productDetailsAssets.servicos.oCuidadoQueSuaNovaFiatStradaMerece,
      },
      {
        id: "revisao-sob-medida-fiat",
        slug: "revisao-sob-medida-fiat",
        title: "Revisão sob medida fiat",
        highLightedFirst: true,
        parentSlug: "servicos",
        parentTitle: "Serviços",
        descriptionDesktop: (
          <p>
            Mais economia para você e seu carro. Deixe sua Fiat Strada bem
            cuidada e monte um plano de revisão com descontos e preço fixo.
          </p>
        ),
        descriptionMobile: (
          <p>
            Mais economia para você e seu carro. Deixe sua Fiat <br />
            Strada bem cuidada e monte um plano de revisão com <br />
            descontos e preço fixo
          </p>
        ),
        image: productDetailsAssets.servicos.revisaoSobMedidaFiat,
      },
    ],
  },
];

export const getProductDetails = (pDetails: ProductTab[]): MenuLabels[] => {
  return pDetails.reduce<MenuLabels[]>((prev, item) => {
    return [
      ...prev,
      {
        numberId: 0,
        id: `data-${item.id}`,
        label: item.title,
        slug: item.slug,
        url: `/${item.slug}`,
        prefixRelation: PRODUCT_DETAILS_REF,
        isDetails: true,
      },
    ];
  }, []);
};

let index = -1;

export const allSlides: ProductTabSlide[] = productDetails.reduce(
  (prev, curr) => {
    return [
      ...prev,
      ...curr.children.map((c) => {
        index++;
        return {
          ...c,
          index,
          parentSlug: curr.slug,
          tabId: curr.id,
        };
      }),
    ];
  },
  [] as ProductTabSlide[]
);

export default productDetails;
