import { FC, useContext } from "react";
import { MenuLabels } from "@/models";
import { useMobxStores } from "@/store";
import styles from "./ScrollToAnchor.module.scss";
import { observer } from "mobx-react-lite";
import scssStyles from "@/utils/scssStyles";
import { Context } from "@/context";

interface ScrollToAnchorProps {
  reference: MenuLabels | undefined;
  title: string;
  pageSubsection: string;
  className?: string;
}

const ScrollToAnchor: FC<ScrollToAnchorProps> = observer(
  ({ reference, className = "", children, title, pageSubsection }) => {
    const { state } = useContext(Context);
    if (
      !reference ||
      !reference?.slug ||
      !state.layout?.navRefs[reference.slug]
    ) {
      return <div className={className}>{children}</div>;
    }

    return (
      <a
        className={scssStyles([styles.link, className])}
        href={`${process.env.BASE_PREFIX}${reference.slug}`}
        title={title}
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        {children}
      </a>
    );
  }
);

export default ScrollToAnchor;
