import { FC } from "react";

const Pointer: FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17.618" height="21.609" viewBox="0 0 18.5 21.609">
            <title>Clique</title>
            <g transform="translate(0.216 -2.25)">
                <path
                    data-name="Path 626"
                    d="M15.636,42.681H15.6a1.767,1.767,0,0,0-1.191.453,1.857,1.857,0,0,0-1.725-1.259,1.789,1.789,0,0,0-1.278.55A1.831,1.831,0,0,0,9.776,41.38H9.742a1.677,1.677,0,0,0-1.052.35V37.293a1.9,1.9,0,0,0-1.851-1.945,1.9,1.9,0,0,0-1.846,1.944L4.985,46.1l-.5-.605a2.514,2.514,0,0,0-1.776-.933,2.473,2.473,0,0,0-1.853.656l-.434.359a.385.385,0,0,0-.1.475l4.216,8.1a4.347,4.347,0,0,0,3.828,2.4h4.751v0A4.524,4.524,0,0,0,17.487,51.9c0-1.148,0-2,0-2.7,0-1.856,0-2.541,0-4.586A1.892,1.892,0,0,0,15.636,42.681ZM16.707,49.2c0,.694,0,1.553,0,2.7a3.763,3.763,0,0,1-3.6,3.889H8.371A3.579,3.579,0,0,1,5.228,53.8L1.155,45.971l.2-.168.015-.013a1.711,1.711,0,0,1,1.285-.461,1.749,1.749,0,0,1,1.234.653l1.18,1.436a.385.385,0,0,0,.683-.244l.007-9.881a1.135,1.135,0,0,1,1.08-1.173,1.135,1.135,0,0,1,1.079,1.174V43.25c0,.021,0,.042,0,.063s0,.018,0,.027v2.547a.385.385,0,1,0,.771,0V43.263a1.081,1.081,0,0,1,1.052-1.112h.034a1.125,1.125,0,0,1,1.082,1.162v2.331a.385.385,0,0,0,.771,0V43.816a1.065,1.065,0,1,1,2.12,0v1.725a.385.385,0,0,0,.771,0v-.934A1.12,1.12,0,0,1,15.6,43.452h.032a1.118,1.118,0,0,1,1.071,1.161C16.711,46.656,16.71,47.34,16.707,49.2Zm0,0"
                    transform="translate(-0.284 -32.898)"
                    fill="currentColor"
                    stroke="currentColor"
                    strokeWidth="0.4"
                />
            </g>
        </svg>
    )
};

export default Pointer;