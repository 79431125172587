import {
  IncludePrefixResponsiveSizes,
  includeResponsive,
} from "@/utils/imagePrefixes";

const GALLERY_PREFIX = "Gallery/";

interface VideoProps {
  desktop: string;
  mobile: string;
}
export interface GalleryAsset {
  id: string;
  title: string;
  asset: IncludePrefixResponsiveSizes;
  video?: VideoProps;
  alt: string;
}

export const gallery: GalleryAsset[] = [
  {
    id: "ga01",
    title: "Fiat Strada Ultra",
    asset: includeResponsive(`${GALLERY_PREFIX}fiat-strada-ultra`, "webp"),
    alt: "Fiat Strada Ultra",
  },

  {
    id: "ga02",
    title: "Carga",
    asset: includeResponsive(
      `${GALLERY_PREFIX}fiat-strada-ultra-carga`,
      "webp"
    ),
    alt: "Carga",
  },
  {
    id: "ga03",
    title: "Turbo 200",
    asset: includeResponsive(
      `${GALLERY_PREFIX}fiat-strada-ultra-turbo-200`,
      "webp"
    ),
    alt: "Turbo 200",
  },
  {
    id: "ga04",
    title: "Banco",
    asset: includeResponsive(
      `${GALLERY_PREFIX}fiat-strada-ultra-banco-ultra`,
      "webp"
    ),
    alt: "Banco",
  },

  {
    id: "ga05",
    title: "Puxador Interno Porta",
    asset: includeResponsive(
      `${GALLERY_PREFIX}fiat-strada-ultra-puxador-interno-porta`,
      "webp"
    ),
    alt: "Puxador Interno Porta",
  },
  {
    id: "ga06",
    title: "Câmbio",
    asset: includeResponsive(
      `${GALLERY_PREFIX}fiat-strada-ultra-cambio`,
      "webp"
    ),
    alt: "Câmbio",
  },
  {
    id: "ga07",
    title: "Soleira",
    asset: includeResponsive(
      `${GALLERY_PREFIX}fiat-strada-ultra-soleira`,
      "webp"
    ),
    alt: "Soleira",
  },
  {
    id: "ga08",
    title: "Traseira",
    asset: includeResponsive(
      `${GALLERY_PREFIX}fiat-strada-ultra-traseira`,
      "webp"
    ),
    alt: "Traseira",
  },
];

export const galleryThumbs = gallery.map((g) => ({
  ...g,
  thumb: `${g.asset.path}-thumb.${g.asset.extension}`,
}));
