import { FC } from "react";

interface VimeoEmbedProps {
  title: string;
  id: string;
  className?: string;
}

const VimeoEmbed: FC<VimeoEmbedProps> = ({ title, id, className = "" }) => {
  return (
    <>
      <iframe
        src={`https://player.vimeo.com/video/${id}?h=5f2b4628e5&loop=1&autopause=1&title=0&byline=0&portrait=0&controls=true`}
        title={title}
        className={className}
        frameBorder="0"
        allowFullScreen
        loading="lazy"
      />
    </>
  );
};

export default VimeoEmbed;
