import { MetaType } from "@models/index";
import {
  ProjectBrand,
  ProjectInfoInterface,
  ProjectType,
} from "@models/ProjectInfo";
import { PREFIX } from "@utils/imagePrefixes";

export const PRODUCT_NAME = "Template";
export const BRAND_NAME = "Fiat";
export const PAGE_TITLE = `${BRAND_NAME}`;

export const project: ProjectInfoInterface = {
  year: "2025",
  version: "1.0",
  debug: true,
  product: "strada",
  brand: ProjectBrand.STRADA,
  type: ProjectType.LANDING_PAGE,
  url: "https://www.strada.fiat.com.br",
};

export const metaTags: MetaType = {
  description:
    "A inovação está no DNA da Nova Fiat Strada. Conheça os detalhes desse modelo incrivelmente surpreeendente",
  title: "Nova Fiat Strada | Fiat",
  brandName: "Fiat",
  productName: "Strada",
  heading: "Fiat Strada",
  image: `${PREFIX}og-image.webp`,
  twitter: {
    card: "summary",
    site: "https://twitter.com/fiatbr",
    imageAlt: "Fiat Strada",
    image: `${PREFIX}og-image.webp`,
  },
  facebook: {
    type: "website",
    image: `${PREFIX}og-image.webp`,
    imageAlt: "Fiat Strada",
    imageWidth: "1200",
    imageHeight: "630",
  },
  url: "https://www.strada.fiat.com.br",
  imageAlt: "Fiat Strada",
};

export const STORAGE_PREFIX = "@digital_code/";

export const brandLinks = {
  mainUrl: "https://www.strada.fiat.com.br",
  privacyPolicy: "https://www.ram.com.br/politica-de-privacidade.html",
  twitter: "https://twitter.com/fiatbr",
  facebook: "https://www.facebook.com/fiatbr",
  instagram: "https://www.instagram.com/fiatbr/",
  youtube: "https://www.youtube.com/fiatbr",
};

export * as menu from "../data/menu.data";
