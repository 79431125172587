import { CTAsSection, MenuCTAs, MenuLabels } from '@/models';
import { ProductTab } from './product-details.data';
import Whatsapp from '@/utils/whatsapp';

export const PRODUCT_DETAILS_PREFIX = 'tudo-sobre';
export const PRODUCT_DETAILS_REF: MenuLabels = {
  numberId: 6,
  id: 'tudo-sobre',
  label: 'Tudo sobre a nova Strada',
  slug: PRODUCT_DETAILS_PREFIX,
  url: `/${PRODUCT_DETAILS_PREFIX}`,
  nestedPrefix: PRODUCT_DETAILS_PREFIX,
};

const getProductDetails = (pDetails: ProductTab[]): MenuLabels[] => {
  if (!pDetails) {
    return [];
  }
  return pDetails.reduce((prev, item, index) => {
    return [
      ...prev,
      {
        id: `data-menu-details-${index}`,
        label: item.title,
        slug: `${item.slug}`,
        url: `${item.slug}`,
        nestedPrefix: PRODUCT_DETAILS_PREFIX,
        reference: PRODUCT_DETAILS_REF,
        tabId: item.id,
        isDetails: true,
      } as MenuLabels,
    ];
  }, [] as MenuLabels[]);
};

export const links = {
  reserve: 'https://strada.fiat.com.br/monte.html',
  financiamento: 'https://strada.fiat.com.br/monte.html',
  monte: 'https://strada.fiat.com.br/monte.html',
  concessionarias: 'https://www.fiat.com.br/concessionarias.html',
  whatsapp: Whatsapp.contact(),
  compre: 'https://www.fiat.com.br/compre/fiat-strada.html',
  ofertas: 'https://ofertas.fiat.com.br/?modelCode=281',
  converse: 'https://www.fiat.com.br/fale-conosco.html',
  interesse:
    'https://www.fiat.com.br/compre/fiat-strada.html?mvs=281&year=2025',
  fichaTecnica: '/',
  banne_125_anos: 'http://fiat.com.br/tributo-125.html',
  picapes: 'https://ofertas.fiat.com.br/?modelCode=281'
};

export const COMPRE_TITLE = 'Compre a sua';
export const TENHO_INTERESSE = 'Tenho interesse';
export const MONTE_TITLE = 'Monte a sua';
export const FINANCIAMENTO_TITLE = 'Simule um financiamento';
export const CONCESSIONARIAS_TITLE = 'Concessionárias';
export const OFERTAS_TITLE = 'Ofertas Fiat';
export const RESERVE_TITLE = 'Reserve a sua';
export const CONVERSE = 'Converse';
export const FICHA_TECNICA = 'Baixar Ficha Técnica';

export const notMenuAnchors: () => MenuLabels[] = () => [
  {
    numberId: 0,
    id: 'notMenuAnchors-0',
    label: '',
    url: '/',
    slug: 'sound-design',
  },
];

export const dataMenuLabelMobile: () => MenuLabels[] = () => [
  {
    numberId: 0,
    id: 'datamenu-0',
    label: 'Nova Fiat Strada',
    url: '/',
    slug: '',
  },
  {
    numberId: 1,
    id: 'datamenu-1',
    label: 'Tributo 125 anos',
    url: '/',
    slug: 'tributo-125-anos',
  },
  {
    numberId: 2,
    id: 'datamenu-2',
    label: 'Versões',
    url: '/versoes',
    slug: 'versoes',
  },
  {
    numberId: 3,
    id: 'datamenu-3',
    label: 'Produtor Rural e Empresas',
    url: '/rural-e-empresas',
    slug: 'rural-e-empresas',
  },
  {
    numberId: 4,
    id: 'datamenu-4',
    label: 'Galeria',
    url: '/galeria',
    slug: 'galeria',
  },
  {
    numberId: 5,
    id: 'datamenu-5',
    label: 'Tudo Sobre Fiat Strada',
    url: '/tudo-sobre',
    slug: 'tudo-sobre',
  },
  //   ...getProductDetails(productDetails),
];

export const dataMenuLabels: () => MenuLabels[] = () => [
  {
    numberId: 0,
    id: 'datamenu-0',
    label: 'Nova Fiat Strada',
    url: '/',
    slug: '',
  },
  {
    numberId: 1,
    id: 'datamenu-1',
    label: 'Tributo 125 anos',
    url: '/',
    slug: 'tributo-125-anos',
  },
  {
    numberId: 2,
    id: 'datamenu-2',
    label: 'Versões',
    url: '/versoes',
    slug: 'versoes',
  },
  {
    numberId: 3,
    id: 'datamenu-3',
    label: 'Rural e Empresas',
    url: '/rural-e-empresas',
    slug: 'rural-e-empresas',
  },
  {
    numberId: 4,
    id: 'datamenu-4',
    label: 'Fotos',
    url: '/galeria',
    slug: 'galeria',
  },
  {
    numberId: 5,
    id: 'datamenu-5',
    label: 'Tudo Sobre',
    url: '/tudo-sobre',
    slug: 'tudo-sobre',
  },
  //   ...getProductDetails(productDetails),
];

export const dataCTASection: CTAsSection[] = [
  {
    id: 'datamenu-0',
    label: FINANCIAMENTO_TITLE,
    url: links.financiamento,
  },
  {
    id: 'datamenu-1',
    label: CONCESSIONARIAS_TITLE,
    url: links.concessionarias,
  },
  {
    id: 'datamenu-2',
    label: OFERTAS_TITLE,
    url: links.ofertas,
  },
];
export const dataCTASectionMobile: CTAsSection[] = [
  {
    id: 'datamenu-4',
    label: MONTE_TITLE,
    url: links.monte,
  },
  {
    id: 'datamenu-3',
    label: COMPRE_TITLE,
    url: links.compre,
  },
  {
    id: 'datamenu-0',
    label: FINANCIAMENTO_TITLE,
    url: links.financiamento,
  },
  {
    id: 'datamenu-1',
    label: CONCESSIONARIAS_TITLE,
    url: links.concessionarias,
  },
  {
    id: 'datamenu-2',
    label: OFERTAS_TITLE,
    url: links.ofertas,
  },
];

export const dataMenuButtonsMobile: MenuCTAs[] = [
  {
    id: 'datamenubutton-1',
    label: MONTE_TITLE,
    url: links.monte,
  },
  {
    id: 'datamenubutton-2',
    label: FINANCIAMENTO_TITLE,
    url: links.financiamento,
  },
  {
    id: 'datamenubutton-3',
    label: COMPRE_TITLE,
    url: links.compre,
    backgroundColor: 'transparent',
    color: '#FFF',
  },
  {
    id: 'datamenubutton-4',
    label: CONCESSIONARIAS_TITLE,
    url: links.concessionarias,
  },
  {
    id: 'datamenubutton-5',
    label: OFERTAS_TITLE,
    url: links.ofertas,
  },
];

export const dataMenuButtons: MenuCTAs[] = [
  {
    id: 'datamenubutton-1',
    label: MONTE_TITLE,
    url: links.reserve,
  },
  {
    id: 'datamenubutton-2',
    label: COMPRE_TITLE,
    url: links.compre,
  },
];

export const versionCtaButton = [
  {
    id: 'ctaButton-0',
    label: TENHO_INTERESSE,
    url: links.compre,
    backgroundColor: '#FFFFFF',
    color: '#000000',
    excludeArrow: false,
  },
];
