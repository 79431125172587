import { brand } from '@/assets';
import { Context } from '@/context';
import {
  dataMenuButtons,
  dataMenuButtonsMobile,
  dataMenuLabelMobile,
  dataMenuLabels,
} from '@/data/menu.data';
import productDetails, { ProductTab } from '@/data/product-details.data';
import DataLayer from '@/utils/DataLayer';
import scssStyles from '@/utils/scssStyles';
import { observer } from 'mobx-react-lite';
import { FC, useContext, useEffect, useState } from 'react';
import { CTAButton, Conditional } from '..';
import styles from './navigation.module.scss';
import Image from 'next/image';

const Navigation: FC = observer(() => {
  const { state, dispatch } = useContext(Context);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const onScroll = () => setOffset(window.scrollY);
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const handleSelectedDetailTab = (
    tab: ProductTab | undefined | null
  ): void => {
    if (tab) {
      dispatch({ type: 'SET_CURRENTLY_VISIBLE_NAV', payload: { tab: tab } });
    }

    dispatch({
      type: 'SET_DETAIL_TAB',
      payload: {
        isMobile: true,
        selectedDetailTab: tab,
      },
    });
  };

  return (
    <>
      {state.layout.menuOpen && (
        <div
          className={styles.gestureDetector}
          onClick={() => {
            DataLayer.closeEvent({
              elementCategory: 'imagem',
              element: 'menu',
              pageSection: 'header',
              pageSubsection: 'menu',
            });
            dispatch({
              type: 'SET_MENU_OPEN',
              payload: { menuOpen: false },
            });
          }}
        />
      )}
      <nav
        id="mainNav"
        className={scssStyles([
          styles.container,
          state.layout.menuOpen ? styles.open : '',
        ])}
        style={{
          top: state.layout.bannerActive ? '53px' : 0,
          backgroundColor: !state.layout.isDesktop
            ? '#18131f'
            : offset > 10 || state.layout.menuColor
            ? '#363636'
            : '#272727',
          transition: 'background-color 0.5s ease',
        }}
      >
        <div className={styles.scroller}>
          <Conditional notOn={'desktop'}>
            {dataMenuLabelMobile().map((item, index) => {
              const active = item.id === state.layout.currentlyVisibleNav;
              return (
                <div className={styles.menuItem} key={`menuItem-${item.id}`}>
                  <a
                    key={item.id}
                    className={scssStyles([
                      styles.menuLinks,
                      active ? styles.active : '',
                    ])}
                    href={item.url}
                    onClick={(e) => {
                      if (item.reference) {
                        DataLayer.clickEvent({
                          elementCategory: 'link',
                          element: item.label,
                          pageSection: 'header',
                          pageSubsection: 'menu',
                        });
                        dispatch({
                          type: 'SCROLL_TO_REFERENCE',
                          payload: { reference: item.reference },
                        });
                        const tabReference = productDetails.find(
                          (p) => p.slug === item.slug
                        );
                        if (tabReference) {
                          handleSelectedDetailTab(
                            state.layout.selectedDetailTab === tabReference.id
                              ? null
                              : tabReference
                          );
                        }
                        dispatch({
                          type: 'SET_MENU_OPEN',
                          payload: { menuOpen: false },
                        });
                      } else {
                        if (index === 0) {
                          window?.scrollTo({ top: 0, behavior: 'smooth' });
                        } else {
                          dispatch({
                            type: 'SCROLL_TO_REFERENCE',
                            payload: { reference: item },
                          });
                        }
                        dispatch({
                          type: 'SET_MENU_OPEN',
                          payload: { menuOpen: false },
                        });
                      }

                      e.preventDefault();
                    }}
                  >
                    <span className={scssStyles([styles.linkUnderline])}>
                      {item.label}
                    </span>
                  </a>
                </div>
              );
            })}

            {!!dataMenuButtonsMobile.length && (
              <div className={styles.buttonsHolder}>
                {dataMenuButtonsMobile.map((item) => {
                  return (
                    <CTAButton
                      key={`menu-cta-${item.id}`}
                      href={item.url}
                      className={styles.menuButton}
                      text={item.label}
                      title={item.label}
                      iconCode={item.iconCode}
                      width="100%"
                      handleClick={() =>
                        DataLayer.clickEvent({
                          elementCategory: 'cta',
                          element: item.label,
                          pageSection: 'header',
                          pageSubsection: 'menu',
                        })
                      }
                    />
                  );
                })}
              </div>
            )}
          </Conditional>

          <Conditional notOn={'mobile'}>
            <div className={styles.productLogo}>
              <Image src={brand.logoNav} alt={''} width={117} height={15} />
            </div>

            <div className={styles.menuItemArea}>
              {dataMenuLabels().map((item, index) => {
                const reference = state.layout.currentlyVisibleNav;
                const active = item.id === reference;

                return (
                  <div className={styles.menuItem} key={`menuItem-${item.id}`}>
                    <a
                      key={item.id}
                      className={scssStyles([
                        styles.menuLinks,
                        active ? styles.active : '',
                      ])}
                      href={`/strada${item.url}`}
                      onClick={(e) => {
                        DataLayer.clickEvent({
                          elementCategory: 'link',
                          element: item.label,
                          pageSection: 'header',
                          pageSubsection: 'menu',
                        });
                        if (index === 0) {
                          window?.scrollTo({ top: 0, behavior: 'smooth' });
                        } else {
                          dispatch({
                            type: 'SCROLL_TO_REFERENCE',
                            payload: { reference: item },
                          });
                        }
                        dispatch({
                          type: 'SET_MENU_OPEN',
                          payload: { menuOpen: false },
                        });

                        e.preventDefault();
                      }}
                    >
                      <span className={scssStyles([styles.linkUnderline])}>
                        {item.label}
                      </span>
                    </a>
                  </div>
                );
              })}
            </div>

            {!!dataMenuButtons.length && (
              <div className={styles.buttonsHolder}>
                {dataMenuButtons.map((item) => {
                  return (
                    <CTAButton
                      key={`menu-cta-${item.id}`}
                      href={item.url}
                      className={styles.menuButton}
                      text={item.label}
                      title={item.label}
                      handleClick={() =>
                        DataLayer.clickEvent({
                          elementCategory: 'cta',
                          element: item.label,
                          pageSection: 'header',
                          pageSubsection: 'menu',
                        })
                      }
                    />
                  );
                })}
              </div>
            )}
          </Conditional>
        </div>
      </nav>
    </>
  );
});

export default Navigation;
