import { GalleryAsset, gallery } from "@/assets/gallery";
import productDetails, {
  ProductTab,
  allSlides,
  getProductDetails,
} from "@/data/product-details.data";
import { MenuLabels, NavRefs } from "@/models";
import { Car, cars } from "@/sections/versions-section/v3/version";
import { SlugSuffixes } from "@/types/layoutReducer.type";
import { reducerActionType } from "@/types/reducerActionType";
import { setCurrentlyVisibleNav, setSlugSuffixes } from "@utils/helpers";
import SwiperCore from "swiper";

interface MobileSwiperController {
  [x: string]: SwiperCore | null;
}

export interface LayoutType {
  selectedGalleryImage: GalleryAsset;
  presentationSwiperController: SwiperCore | undefined;
  presentationIndex: number;
  pageX: number;
  isDesktop: boolean;
  menuOpen: boolean;
  navRefs: NavRefs;
  scrollToRef?: (reference: MenuLabels) => void;
  currentlyVisibleNav: MenuLabels["id"];
  galleryExpanded: boolean;
  expandedSwiperController: SwiperCore | undefined;
  productDetailsSwiperController: SwiperCore | undefined;
  productDetailsMobileSwiperController: MobileSwiperController;
  selectedDetailTab: string;
  currentTab: ProductTab | undefined;
  imagesPreloaded: boolean;
  reserveCtaImages: any[];
  slugSuffixes: SlugSuffixes;
  stopHistoryChange: boolean;
  queryString: URLSearchParams | null;
  comparativeOpen: boolean;
  mainDisplayIndex: number;
  bannerActive: boolean;
  menuColor: boolean;
  versionPhotoSrc: string;
  requestFinished: boolean;
  colorActive: string;
  currentVersion: Car;
}

export const layoutInitialState: LayoutType = {
  // layout config
  pageX: 0,
  isDesktop: true,
  menuOpen: false,
  navRefs: {},
  currentlyVisibleNav: "",
  bannerActive: false,
  presentationSwiperController: undefined,
  presentationIndex: 0,
  selectedGalleryImage: gallery[0],
  galleryExpanded: false,
  expandedSwiperController: undefined,
  productDetailsSwiperController: undefined,
  productDetailsMobileSwiperController: {},
  selectedDetailTab: "",
  currentTab: undefined,
  imagesPreloaded: false,
  reserveCtaImages: [],
  slugSuffixes: {},
  stopHistoryChange: false,
  queryString: null,
  comparativeOpen: false,
  mainDisplayIndex: 0,
  menuColor: false,
  versionPhotoSrc: "",
  requestFinished: false,
  colorActive: "",
  currentVersion: cars[0],
};

export const layoutReducer = (
  state: LayoutType,
  action: reducerActionType
): any => {
  switch (action.type) {
    case "SET_IS_DESKTOP":
      return {
        ...state,
        isDesktop: action.payload.isDesktop,
      };
    case "SET_PAGE":
      return {
        ...state,
        pageX: action.payload.pageX,
      };
    case "SET_MENU_OPEN":
      return {
        ...state,
        menuOpen: action.payload.menuOpen,
      };
    case "SET_NAV_REF":
      return {
        ...state,
        navRefs: {
          ...state.navRefs,
          [action.payload.slug]: action.payload.ref,
        },
      };
    case "SET_CURRENTLY_VISIBLE_NAV":
      if (action.payload.tab) {
        const reference = getProductDetails(productDetails).find(
          (p) => p.id === `data-${action.payload.tab?.id}`
        );

        const sufffix = allSlides.filter(
          (tab) => tab.tabId === action.payload.tab.id
        )[0]?.slug;

        if (reference && action.payload.tab) {
          return {
            ...state,
            currentlyVisibleNav: setCurrentlyVisibleNav(
              reference,
              sufffix,
              state.queryString
            ),
          };
        }
      } else {
        return {
          ...state,
          currentlyVisibleNav: setCurrentlyVisibleNav(
            action.payload.view,
            action.payload.suffix,
            state.queryString
          ),
        };
      }
    // apresentacao
    case "SET_PRESENTATION_INDEX":
      return {
        ...state,
        presentationIndex: action.payload.presentationIndex,
      };
    case "SET_PRESENTATION_CONTROLLER":
      return {
        ...state,
        presentationSwiperController:
          action.payload.presentationSwiperController,
      };
    // galeria
    case "SET_EXPANDED_CONTROLLER":
      return {
        ...state,
        expandedSwiperController: action.payload.expandedSwiperController,
      };
    case "SET_GALLERY_EXPANDED":
      return {
        ...state,
        galleryExpanded: action.payload.galleryExpanded,
      };
    case "SET_GALLERY_IMAGE":
      return {
        ...state,
        selectedGalleryImage: action.payload.selectedGalleryImage,
      };
    case "SET_PRODUCT_DETAILS_CONTROLLER":
      return {
        ...state,
        productDetailsSwiperController:
          action.payload.productDetailsSwiperController,
      };
    case "SET_DETAIL_TAB":
      if (action.payload.isMobile) {
        return {
          ...state,
          selectedDetailTab: action.payload.selectedDetailTab
            ? action.payload.selectedDetailTab.id
            : "",
          currentTab: productDetails.find((tab) =>
            tab.id === action.payload.selectedDetailTab
              ? action.payload.selectedDetailTab.id
              : ""
          ),
        };
      } else {
        return {
          ...state,
          selectedDetailTab: action.payload.selectedDetailTab
            ? action.payload.selectedDetailTab.id
            : "",
          currentTab: productDetails.find(
            (tab) => tab.id === action.payload.selectedDetailTab.id
          ),
        };
      }

    case "GET_CURRENT_TAB":
      return productDetails.find((m) => m.id === state.selectedDetailTab);
    case "SWIPE_TO_TAB":
      if (
        action.payload.indexInfo &&
        !state.productDetailsSwiperController?.destroyed
      ) {
        const index = allSlides.findIndex(
          (s) => s.id === action.payload?.tab.id
        );
        state.productDetailsSwiperController?.slideTo(index);
      } else {
        if (!state.productDetailsSwiperController?.destroyed) {
          const index = allSlides.findIndex(
            (s) => s.tabId === action.payload?.tab.id
          );

          state.productDetailsSwiperController?.slideTo(index);
        }
      }
    case "IS_IMAGES_PRELOADED":
      return {
        ...state,
        imagesPreloaded: action.payload.imagesPreloaded,
      };
    case "SET_RESERVE_CTA_IMAGES":
      return {
        ...state,
        reserveCtaImages: action.payload.reserveCtaImages,
      };
    case "SET_SLUG_SUFFIXES":
      return {
        ...state,
        slugSuffixes: setSlugSuffixes(
          action.payload.reference,
          action.payload.func
        ),
      };
    case "SET_STOP_HISTORY_CHANGE": {
      return {
        ...state,
        stopHistoryChange: action.payload.stopHistoryChange,
      };
    }
    case "SET_QUERY_STRING": {
      return {
        ...state,
        queryString: action.payload.queryString,
      };
    }
    case "SCROLL_TO_REFERENCE": {
      const element = state.navRefs[action.payload.reference.slug]
        ?.current as HTMLDivElement;

      if (state.isDesktop) {
        element.scrollIntoView({ behavior: "smooth" });

        break;
      } else {
        element.scrollIntoView({ behavior: "smooth" });
      }
      break;
    }
    case "SET_COMPARATIVE": {
      return {
        ...state,
        comparativeOpen: action.payload.comparative,
      };
    }
    case "SET_MAIN_DISPLAY_INDEX": {
      return {
        ...state,
        mainDisplayIndex: action.payload.mainDisplayIndex,
      };
    }
    case "SET_BANNER_ACTIVE": {
      return {
        ...state,
        bannerActive: action.payload.bannerActive,
      };
    }
    case "SET_MENU_COLOR": {
      return {
        ...state,
        menuColor: action.payload.menuColor,
      };
    }
    case "SET_VERSION_PHOTO_SRC": {
      return {
        ...state,
        versionPhotoSrc: action.payload.versionPhotoSrc,
      };
    }
    case "SET_PRODUCT_DETAILS_MOBILE_SWIPER_CONTROLLER":
      return {
        ...state,
        productDetailsMobileSwiperController: {
          ...state.productDetailsMobileSwiperController,
          [action.payload.tabId]:
            action.payload.productDetailsMobileSwiperController,
        },
      };

    case "SET_REQUEST_FINISHED":
      return {
        ...state,
        requestFinished: action.payload.requestFinished,
      };
    case "SET_COLOR_ACTIVE":
      return {
        ...state,
        colorActive: action.payload.colorActive,
      };
    case "SET_CURRENT_VERSION": {
      return {
        ...state,
        currentVersion: action.payload.currentVersion,
      };
    }
  }

  return state;
};
