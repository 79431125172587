import Cookies from 'js-cookie';

const contact = (): string => {
  const WHATSAPP_TEXT = 'Eu quero minha Fiat Strada 0km.';
  const WHATSAPP_MESSAGE = WHATSAPP_TEXT.replaceAll(' ', '+');
  const WHATSAPP_NUMBER = '553121236000';
  const WHATSAPP_PROTOCOL =
    process.env.NODE_ENV === 'development'
      ? (Cookies.set('mockCookie', 'abc123'),
        Cookies.get('mockCookie'))
      : Cookies.get('MuchMore_ptc');

  return `https://api.whatsapp.com/send?phone=${WHATSAPP_NUMBER}&text=${WHATSAPP_MESSAGE}+Protocolo+${WHATSAPP_PROTOCOL}`;
};

const Whatsapp = {
  contact,
};

export default Whatsapp;
