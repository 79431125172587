import { Context } from "@/context";
import {
  links as brandLinks,
  COMPRE_TITLE,
  MONTE_TITLE,
} from "@/data/menu.data";
import scssStyles from "@/utils/scssStyles";
import { observer } from "mobx-react-lite";
import {FC, useContext, useEffect, useState} from "react";
import { useIdleTimer } from "react-idle-timer";
import { CTAButton } from "..";
import styles from "./StickyCTA.module.scss";
import DataLayer from "@/utils/DataLayer";

const StickyCTA: FC = observer(() => {
  const [isShown, setIsShown] = useState(false);
  const [delay, setDelay] = useState(false);
  const { state } = useContext(Context);

  const hide = () => {
    if (isShown) {
      setIsShown(false);
    }
  };

  const show = () => {
    setTimeout(() => {
      setIsShown(true);
    }, 300);
  };

  useEffect(() => {
    setTimeout(() => {
      setDelay(false);
    }, 500);
  }, []);

  useIdleTimer({
    timeout: 60 * 3,
    onActive: () => hide(),
    onAction: () => show(),
    debounce: 2000,
  });

   if (state.layout.isDesktop || delay) {
     return null;
   }
   return (
     <>
       {state.layout.galleryExpanded ? (
         <></>
       ) : (
         <div
           className={scssStyles([
             styles.container,
             isShown ? styles.active : "",
           ])}
         >
           <CTAButton
             href={brandLinks.monte}
             larger
             className={scssStyles([styles.button, styles.interest])}
             text={MONTE_TITLE}
             title={MONTE_TITLE}
             handleClick={() => {
               DataLayer.clickEvent({
                 element: MONTE_TITLE,
                 elementCategory: "cta",
                 pageSection: "conteudo",
                 pageSubsection: "float",
               });
             }}
           />
           <CTAButton
             href={brandLinks.compre}
             larger
             className={styles.button}
             text={COMPRE_TITLE}
             title={COMPRE_TITLE}
             handleClick={() => {
               DataLayer.clickEvent({
                 element: COMPRE_TITLE,
                 elementCategory: "cta",
                 pageSection: "conteudo",
                 pageSubsection: "float",
               });
             }}
           />
         </div>
       )}
     </>
   );
});

export default StickyCTA;
