import { includeResponsive } from "@/utils/imagePrefixes";

const PREFIX = `${process.env.BASE_PREFIX}images/`;
const SECTION_PREFIX = "ProductDetails/";

const DESIGN_PREFIX = `${SECTION_PREFIX}design-bello/`;
export const belloDesign = {
  designItaliano: includeResponsive(
    `${DESIGN_PREFIX}farois-de-neblina-em-led`,
    "webp",
    {
      prefix: PREFIX,
    }
  ),
  cabineCom5LugaresE4Portas: includeResponsive(
    `${DESIGN_PREFIX}fiat-strada-bello-design-cabine-5-lugares-4-portas`,
    "webp",
    {
      prefix: PREFIX,
    }
  ),
  espacoEConforto: includeResponsive(
    `${DESIGN_PREFIX}fiat-strada-bello-design-espaco-conforto`,
    "webp",
    {
      prefix: PREFIX,
    }
  ),
  rodasEmLigaLeve: includeResponsive(
    `${DESIGN_PREFIX}rodas-em-liga-leve`,
    "webp",
    { prefix: PREFIX }
  ),
  designExterno: includeResponsive(
    `${DESIGN_PREFIX}fiat-strada-bello-design-design-externo`,
    "webp",
    {
      prefix: PREFIX,
    }
  ),
};

const INTERIOR_PREFIX = `${SECTION_PREFIX}forca-e-potencia/`;
export const forcaEPotencia = {
  cacamba: includeResponsive(
    `${INTERIOR_PREFIX}fiat-strada-forca-performance-cacamba`,
    "webp",
    {
      prefix: PREFIX,
    }
  ),
  maiorAlturaDaCategoria: includeResponsive(
    `${INTERIOR_PREFIX}fiat-strada-forca-performance-maior-altura-categoria`,
    "webp",
    { prefix: PREFIX }
  ),
  vaoModular: includeResponsive(
    `${INTERIOR_PREFIX}fiat-strada-forca-performance-vao-modular`,
    "webp",
    {
      prefix: PREFIX,
    }
  ),
  suspensaoDoFeixeDeMolas: includeResponsive(
    `${INTERIOR_PREFIX}fiat-strada-forca-performance-suspensao-feixe-molas`,
    "webp",
    {
      prefix: PREFIX,
    }
  ),
  melhorConsumoDeCombustivel: includeResponsive(
    `${INTERIOR_PREFIX}fiat-strada-forca-performance-melhor-consumo-combustivel`,
    "webp",
    {
      prefix: PREFIX,
    }
  ),
  motorTurbo130Cv: includeResponsive(
    `${INTERIOR_PREFIX}fiat-strada-forca-performance-motor-turbo-130cv`,
    "webp",
    { prefix: PREFIX }
  ),
  tcControleDeTracao: includeResponsive(
    `${INTERIOR_PREFIX}fiat-strada-forca-performance-tc-controle-tracao`,
    "webp",
    {
      prefix: PREFIX,
    }
  ),
};

const TECNOLOGIA_PREFIX = `${SECTION_PREFIX}tecnologia/`;
export const tecnologia = {
  centralMultimidia: includeResponsive(
    `${TECNOLOGIA_PREFIX}fiat-strada-tecnologia-central-multimidia`,
    "webp",
    { prefix: PREFIX }
  ),
  wirelessCharger: includeResponsive(
    `${TECNOLOGIA_PREFIX}fiat-strada-tecnologia-wireless-charger`,
    "webp",
    { prefix: PREFIX }
  ),
  comandosNoVolante: includeResponsive(
    `${TECNOLOGIA_PREFIX}comandos-no-volante`,
    "webp",
    { prefix: PREFIX }
  ),
  arCondicionadoDeSerie: includeResponsive(
    `${TECNOLOGIA_PREFIX}fiat-strada-tecnologia-ar-condicionado-serie`,
    "webp",
    { prefix: PREFIX }
  ),
  painel35Tft: includeResponsive(
    `${TECNOLOGIA_PREFIX}fiat-strada-tecnologia-painel-35-tft`,
    "webp",
    {
      prefix: PREFIX,
    }
  ),
  novaTecnologiaNaTampaTraseira: includeResponsive(
    `${TECNOLOGIA_PREFIX}fiat-strada-tecnologia-nova-tecnologia-tampa-traseira`,
    "webp",
    {
      prefix: PREFIX,
    }
  ),
};

const SEGURANCA_PREFIX = `${SECTION_PREFIX}seguranca/`;
export const seguranca = {
  airbagsLaterais: includeResponsive(
    `${SEGURANCA_PREFIX}fiat-strada-seguranca-airbags-laterais`,
    "webp",
    {
      prefix: PREFIX,
    }
  ),
  cameraDeReESensorDeEstacionamento: includeResponsive(
    `${SEGURANCA_PREFIX}fiat-strada-seguranca-camera-re-sensor-estacionamento`,
    "webp",
    { prefix: PREFIX }
  ),
  controleDeEstabilidade: includeResponsive(
    `${SEGURANCA_PREFIX}fiat-strada-seguranca-controle-estabilidade`,
    "webp",
    { prefix: PREFIX }
  ),
  isofix: includeResponsive(
    `${SEGURANCA_PREFIX}fiat-strada-seguranca-isofix`,
    "webp",
    {
      prefix: PREFIX,
    }
  ),
};

const ACESSORIOS_PREFIX = `${SECTION_PREFIX}acessorios/`;
export const acessorios = {
  maisDe50AcessoriosOriginaisParaSuaPicape: includeResponsive(
    `${ACESSORIOS_PREFIX}mais-de-50-acessorios-originais-para-sua-picape`,
    "webp",
    { prefix: PREFIX }
  ),
  extensorDeCacamba: includeResponsive(
    `${ACESSORIOS_PREFIX}extensor-de-cacamba`,
    "webp",
    { prefix: PREFIX }
  ),
  engateReboque: includeResponsive(
    `${ACESSORIOS_PREFIX}engate-reboque`,
    "webp",
    {
      prefix: PREFIX,
    }
  ),
  estriboLateral: includeResponsive(
    `${ACESSORIOS_PREFIX}estribo-lateral`,
    "webp",
    { prefix: PREFIX }
  ),
  santoAntonio: includeResponsive(`${ACESSORIOS_PREFIX}santo-antonio`, "webp", {
    prefix: PREFIX,
  }),
  capotaMaritima: includeResponsive(
    `${ACESSORIOS_PREFIX}capota-maritima`,
    "webp",
    {
      prefix: PREFIX,
    }
  ),
};

const SERVICOS_PREFIX = `${SECTION_PREFIX}servicos/`;
export const servicos = {
  planoDeRevisoesMaisCompletoDaCategoria: includeResponsive(
    `${SERVICOS_PREFIX}fiat-strada-servicos-plano-revisoes-mais-completo-categoria`,
    "webp",
    { prefix: PREFIX }
  ),
  oCuidadoQueSuaNovaFiatStradaMerece: includeResponsive(
    `${SERVICOS_PREFIX}fiat-strada-servicos-cuidado-que-sua-nova-fiat-strada-merece`,
    "webp",
    { prefix: PREFIX }
  ),
  revisaoSobMedidaFiat: includeResponsive(
    `${SERVICOS_PREFIX}fiat-strada-servicos-revisao-sob-medida-fiat`,
    "webp",
    {
      prefix: PREFIX,
    }
  ),
};
