import { BRAND_PREFIX, PREFIX } from "@/utils/imagePrefixes";

const brand = {
  logoHeaderMobile: `${BRAND_PREFIX}heroLogoMobile.png`,
  logoNav: `${BRAND_PREFIX}heroLogo.svg`,
  logoHeader: `${BRAND_PREFIX}brand/logoHeader.png`,
  logoProduct: `${BRAND_PREFIX}brand/logoProduct.png`,
  logoProductDark: `${BRAND_PREFIX}brand/logoProductDark.png`,
  logoHeaderDesktop: `${BRAND_PREFIX}brand/logoHeaderDesktop.png`,
  whatsBtn: `${BRAND_PREFIX}/whatsapp.svg`,
  logoFooter: `${BRAND_PREFIX}logoFooter.svg`,
};

const ui = {
  menuClosed: `${PREFIX}ui/menuClosed.svg`,
  pointer: `${PREFIX}ui/pointer.png`,
  accordionChevron: `${PREFIX}ui/accordionChevron.svg`,
  ibama: `${PREFIX}ui/ibama.svg`,
  arrowNext: `${PREFIX}ui/arrowNext.svg`,
  arrowPrev: `${PREFIX}ui/arrowPrev.svg`,
};

const profiles = {
  image1: `${PREFIX}profile/fiat-strada-produtor-rural-mobile.webp`,
  image2: `${PREFIX}profile/fiat-strada-empresas-mobile.webp`,
  image3: `${PREFIX}profile/fiat-strada-produtor-rural-desktop.webp`,
  image4: `${PREFIX}profile/fiat-strada-empresas-desktop.webp`,
};

const heroContent = {
  firstContent: `${PREFIX}HeroDesktop/firstContent.svg`,
};

export * as productDetails from "./productDetails";

export { brand, heroContent, profiles, ui };
