import { Context } from "@/context";
import { CardsItemsProps } from "@/models";
import DataLayer from "@/utils/DataLayer";
import scssStyles from "@/utils/scssStyles";
import { observer } from "mobx-react-lite";
import Image from "next/image";
import { FC, useContext } from "react";
import styles from "./Cards.module.scss";

export interface CardsProps {
  items: CardsItemsProps[];
}

const Cards: FC<CardsProps> = observer(({ items }) => {
  const { state, dispatch } = useContext(Context);

  const handleSetMainDisplayIndex = (index: number) => {
    dispatch({
      type: "SET_MAIN_DISPLAY_INDEX",
      payload: { mainDisplayIndex: index },
    });
  };

  return (
    <div className={styles.positionFix}>
      <div className={styles.container}>
        <div className={styles.cards}>
          {items.map((item, index) => {
            return (
              <button
                key={`desktop-stories-button-${index}`}
                onClick={() => {
                  DataLayer.clickEvent({
                    element: `${item.titleTag}`,
                    elementCategory: "card",
                    pageSection: "conteudo",
                    pageSubsection: "nova-strada",
                  });
                  handleSetMainDisplayIndex(index + 1);
                }}
                className={styles.unitaryBox}
                title={item.title2}
              >
                <div
                  id={"thumb" + item.id}
                  className={scssStyles([
                    styles.externalBox,
                    index === state.layout.mainDisplayIndex - 1
                      ? styles.selected
                      : "",
                  ])}
                >
                  <Image
                    className={styles.carPicture}
                    alt={`${item.altTag + ' card ' + item.id}`}
                    title={`${item.titleTag}`}
                    src={item.src.fullPath3x}
                    layout="responsive"
                    objectFit="contain"
                    objectPosition="top right"
                    width={100}
                    height={47.5}
                    />
                  <div className={styles.cardTitleBox}>
                    <p
                      className={scssStyles([
                        styles.cardTitle,
                        state.layout.mainDisplayIndex !== index + 1
                          ? ""
                          : styles.selected,
                      ])}
                    >
                      {item.title}
                    </p>
                  </div>
                </div>
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
});

export default Cards;
