import { FC } from "react";

const ScrollDesktop: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="147"
      height="58.036"
      viewBox="0 0 147 58.036"
    >
      <g
        id="Grupo_18634"
        data-name="Grupo 18634"
        transform="translate(-48 -543)"
      >
        <path
          id="seta"
          d="M0,0,12.1,12,24,0"
          transform="translate(109.5 585.5)"
          fill="none"
          stroke="#fff"
          strokeWidth="5"
        />
        <text
          id="Scroll_para_saber_mais"
          data-name="Scroll para saber mais"
          transform="translate(48 559)"
          fill="#fff"
          fontSize="16"
          fontFamily="FuturaPT-MediumObl, Futura PT"
          fontWeight="500"
          fontStyle="oblique"
          letterSpacing="0.01em"
        >
          <tspan x="0" y="0">
            Scroll para saber mais
          </tspan>
        </text>
      </g>
    </svg>
  );
};

export default ScrollDesktop;
