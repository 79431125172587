import { brand } from '@/assets';
import DataLayer from '@/utils/DataLayer';
import styles from './WhatsappBtn.module.scss';
import { FC } from 'react';
import Whatsapp from '@/utils/whatsapp';
import Image from 'next/image';

interface WhatsappBtnProps {
  id?: string;
}

const WhatsappBtn: FC<WhatsappBtnProps> = ({ id = 'btnWhatsapp' }) => {
  return (
    <button
      className={styles.whatsapp_float}
      onClick={() => {
        const url = Whatsapp.contact();
        DataLayer.clickEvent({
          element: 'quero comprar',
          elementCategory: 'whatsapp',
          pageSection: 'conteudo',
          pageSubsection: 'float',
          path: 'home',
        });

        window.open(url, '_blank', 'noopener,noreferrer');
      }}
    >
      <Image src={brand.whatsBtn} alt={id} width={36} height={36} />
      <span>Quero negociar</span>
    </button>
  );
};

export default WhatsappBtn;
